/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Optional: Eigene Styles */
body {
  font-family: 'Inter', sans-serif;
}

.erzaehlkiste-gradient {
  background: linear-gradient(135deg, #92400e 0%, #b45309 100%);
}